// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";

function getSystemTheme(param) {
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark";
  } else {
    return "light";
  }
}

function getTheme(param) {
  var themePreference = Belt_Option.getWithDefault(Caml_option.null_to_opt(localStorage.getItem("themePreference")), "system");
  if (themePreference === "system") {
    return getSystemTheme(undefined);
  } else {
    return themePreference;
  }
}

function applyTheme(theme) {
  var root = document.documentElement;
  switch (theme) {
    case "dark" :
        root.classList.add("dark");
        return ;
    case "light" :
        root.classList.remove("dark");
        return ;
    default:
      return ;
  }
}

function setThemeBasedOnPreference(param) {
  applyTheme(getTheme(undefined));
}

window.addEventListener("DOMContentLoaded", (function (_event) {
        applyTheme(getTheme(undefined));
      }));

export {
  getSystemTheme ,
  getTheme ,
  applyTheme ,
  setThemeBasedOnPreference ,
}
/*  Not a pure module */
